<template>
    <BT-Blade-Items
        addBladeName="order-slot"
        bladeName="order-slots"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :getParams="bladeData => { return { groupID: bladeData != null && bladeData.data != null ? bladeData.data.groupID : null }}"
        :headers="[
            { text: 'Slot Name', value: 'slotName', title: 1, searchable: true },
            { text: 'Next Attention Date', value: 'nextAttentionDate', textFilter: 'toLongDateAndTime', subtitle: 1, searchable: true }]"
        navigation="order-slots"
        :refreshToggle="refreshToggle"
        title="Order Slots"
        useServerPagination>
        <template v-slot:settings>
            <v-list-item @click="slotToggle = !slotToggle">
                <v-list-item-icon>
                    <v-icon small>mdi-toggle-switch</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <BT-Select-List-Dialog
                        @ok="turnOn"
                        hideButton
                        itemText="slotName"
                        itemValue="id"
                        label="Order Slots"
                        multiple
                        navigation="order-slots"
                        :params="{  }"
                        :showToggle="slotToggle"
                        width="350" />
                    <v-list-item-subtitle>Turn On</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item :to="{ name: 'order-slot-groups-board' }">
                <v-list-item-icon>
                    <v-icon small>mdi-view-dashboard-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Board</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'order-slot-grouper' }">
                <v-list-item-icon>
                    <v-icon small>mdi-timer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Order Slot Grouper</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Order-Slots-Blade',
    components: {
        BTSelectListDialog: () => import('~components/BT-Select-List-Dialog.vue')
    },
    props: {
        bladesData: null
    },
    data: function() {
        return {
            refreshToggle: false,
            slotToggle: false
        }
    },
    methods: {
        async turnOn(res) {
            if (this.isLengthyArray(res)) {
                try {
                    this.isLoading = true;
                    var ids = res.map(y => y.id).toString();
                    await this.$BlitzIt.api.post('order-slots', null, null, '/post/TurnOn?IDs=' + ids);
                    this.refreshToggle = !this.refreshToggle;
                }
                finally {
                    this.isLoading = false;
                }
            }
        }
    }
}
</script>